import config from '../Config';
import 'whatwg-fetch';
import md5 from 'md5';
import {
  authHeader
}
from '../utils/auth-header';

export const userrequestService = {
  subscribe, confirm, unsubscribe, confirmVehicleRequestEmail,
  recordVehicleRequestEmail
};


/*
# User Vehicle Request Email
*/

async function recordVehicleRequestEmail(email, lang) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + email),
    },
    body: JSON.stringify({
      email: email,
      lang: lang
    })
  };

  return fetch(
    `${config.protocol}://${config.apihost}/vehiclerequest/record`,
    requestOptions).then(
    handleResponse);
}



async function confirmVehicleRequestEmail(requestid) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + requestid),
    },
    body: JSON.stringify({
      requestid: requestid
    })
  };

  return fetch(
    `${config.protocol}://${config.apihost}/vehiclerequest/confirm`,
    requestOptions).then(
    handleResponse);
}

/*
# Search Request Subscription
*/

async function subscribe(email, filter, lang, client) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + email),
    },
    body: JSON.stringify({
      email: email,
      filter: filter,
      lang: lang,
      client: client
    })
  };

  return fetch(
    `${config.protocol}://${config.apihost}/subscription/search/subscribe`,
    requestOptions).then(
    handleResponse);
}

async function confirm(searchid) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + searchid),
    },
    body: JSON.stringify({
      searchid: searchid
    })
  };

  return fetch(
    `${config.protocol}://${config.apihost}/subscription/search/confirm`,
    requestOptions).then(
    handleResponse);
}

async function unsubscribe(searchid) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + searchid),
    },
    body: JSON.stringify({
      searchid: searchid
    })
  };

  return fetch(
    `${config.protocol}://${config.apihost}/subscription/search/unsubscribe`,
    requestOptions).then(
    handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
